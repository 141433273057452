<template>
    <div>
        <el-breadcrumb class="Bdh" separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>内容管理</el-breadcrumb-item>
            <el-breadcrumb-item>精选推荐</el-breadcrumb-item>
        </el-breadcrumb>
        <div class="add">
            <el-button @click="toDetail('new','')" size="small">添加</el-button>
        </div>
        <el-table :data="list" border style="width: 100%">
            <el-table-column label="编号" prop="id" width="80"></el-table-column>
            <el-table-column prop="id" v-if="false"></el-table-column>
            <el-table-column prop="title" label="标题"></el-table-column>
            <el-table-column prop="view_count" label="阅读数" width="100"></el-table-column>
            <el-table-column prop="comment_count" label="评论数" width="100"></el-table-column>
            <el-table-column prop="like_count" label="点赞数" width="100"></el-table-column>
            <el-table-column>
                <template slot-scope="scope">
                    <div class="opt">
                        <el-button size="mini" type="primary" icon="el-icon-edit" @click="toDetail('bj',scope.row)">编辑
                        </el-button>
                        <el-button size="mini" type="danger" icon="el-icon-delete" @click="del(scope.row,scope.$index)">
                            删除</el-button>
                        <el-button size="mini" type="warning" icon="el-icon-view" @click="lookcomment(scope.row)">查看评论
                        </el-button>
                    </div>
                </template>
            </el-table-column>
        </el-table>
        <p class="paging">
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="current"
                :page-sizes="pageSizes" :page-size="PageSize" layout="total, sizes, prev, pager, next, jumper"
                :total="totalCount" :hide-on-single-page="value"></el-pagination>
        </p>
        <el-dialog title="查看评论" :visible.sync="comment" width="80%">
            <el-table :data="commentlist" border style="width: 100%">
                <el-table-column prop="id" v-if="false"></el-table-column>
                <el-table-column prop="author_name" label="姓名" width="120"></el-table-column>
                <el-table-column prop="tel" label="电话" width="120"></el-table-column>
                <el-table-column prop="comment" label="内容"></el-table-column>
                <el-table-column width="200">
                    <template slot-scope="scope">
                        <div class="opt">
                            <el-button size="mini" type="danger" icon="el-icon-delete" @click="delcomment(scope.row,scope.$index)">
                                删除</el-button>
                                <el-button size="mini" type="success" icon="el-icon-chat-dot-square" @click="replay(scope.row)">
                                    回复</el-button>
                            <!-- <a @click="delcomment(scope.row,scope.$index)">删除</a> -->
                            <!-- <a @click="replay(scope.row)">回复</a> -->
                        </div>
                    </template>
                </el-table-column>
            </el-table>
            <p class="paging">
                <el-pagination @size-change="handleSizeChange1" @current-change="handleCurrentChange1"
                    :current-page="current1" :page-sizes="pageSizes1" :page-size="PageSize1"
                    layout="total, sizes, prev, pager, next, jumper" :total="totalCount1" :hide-on-single-page="value1">
                </el-pagination>
            </p>
        </el-dialog>
        <el-dialog title="客服回复" :visible.sync="dialogVisible" width="30%">
            <el-form label-width="80px">
                <el-form-item label="姓名">
                    <el-input type="text" v-model="commentreplyname"></el-input>
                </el-form-item>
                <el-form-item label="回复内容">
                    <el-input type="textarea" v-model="commentreply"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="submitkefutalk">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                list: [],
                current: 1,//默认显示第几页
                totalCount: 0,// 总条数，根据接口获取数据长度
                pageSizes: [20],// 个数选择器（可修改）
                PageSize: 20,// 默认每页显示的条数（可修改）
                value: false,
                comment: false,
                commentlist: [],
                current1: 1,//默认显示第几页
                totalCount1: 0,// 总条数，根据接口获取数据长度
                pageSizes1: [20],// 个数选择器（可修改）
                PageSize1: 20,// 默认每页显示的条数（可修改）
                value1: false,
                rowid: '',
                commentreply: '',
                commentreplyname: '',
                replyrow: '',
                dialogVisible: false,
            }
        },
        mounted() {
            this.getdata(1, this.PageSize)
        },
        methods: {
            toDetail(type, item) {
                if (type == 'new') {
                    this.$router.push({ path: '/recommendToday/recTodayDetail', query: { type: type, cate: 2 } });
                } else {
                    this.$router.push({ path: '/recommendToday/recTodayDetail', query: { type: type, id: item.id, cate: 2 } });
                }
            },
            handleSizeChange() { },
            handleCurrentChange(currentPage) {
                console.log(currentPage);
                this.getdata(currentPage, this.PageSize);
            },
            handleSizeChange1() { },
            handleCurrentChange1(currentPage) {
                this.getcomment(currentPage);
            },
            getdata(page, size) {
                this.list = [];
                this.axios.get("/gu/get_gu_articles?page=" + page + "&size=" + size + "&cate=2")
                    .then((rs) => {
                        if (rs.data.code == 0) {
                            // console.log(rs);
                            this.list = rs.data.result;
                            this.totalCount = rs.data.count;
                        } else {
                            this.list = [];
                            this.totalCount = 0;
                            console.log(rs);
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            },
            del(row, index) {//删除
                // console.log(row);
                this.$confirm('此操作将永久删除该文章, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.axios.post("/gu/delete_gu_article", this.qs.stringify({ id: row.id }))
                        .then((rs) => {
                            if (rs.data.code == 0) {
                                this.$message({
                                    message: '删除成功',
                                    type: 'success'
                                });
                                this.list.splice(index, 1);
                            } else {
                                this.$message(rs.data.msg);
                            }
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                });
            },
            lookcomment(row) {
                this.comment = true;
                this.rowid = row.id;
                this.totalCount1 = 0;
                this.commentlist = [];
                this.getcomment(1);
            },
            getcomment(page) {
                this.axios.get("/article/get_gu_article_comment?article_id=" + this.rowid + "&page=" + page + "&size=" + this.PageSize1)
                    .then((rs) => {
                        if (rs.data.code == 0) {
                            this.totalCount1 = rs.data.count;
                            this.commentlist = rs.data.result;
                        } else { }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            },
            delcomment(row, index) {
                this.$confirm('此操作将永久删除该评论, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.axios.post("/article/delete_gu_article_comment", this.qs.stringify({ id: row.id, article_id: this.rowid }))
                        .then((rs) => {
                            if (rs.data.code == 0) {
                                this.$message({
                                    message: '删除成功',
                                    type: 'success'
                                });
                                this.commentlist.splice(index, 1);
                                this.totalCount1--;
                            } else {
                                this.$message(rs.data.msg);
                            }
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                });
            },
            replay(row) {
                this.dialogVisible = true;
                this.replyrow = row;
            },
            submitkefutalk() {
                let form = {
                    comment: this.commentreply,
                    author_id: sessionStorage.getItem('cust_id'),
                    author_name: this.commentreplyname,
                    headimg: '',
                    reply_id: this.replyrow.id,
                    article_id: this.replyrow.article_id,
                }
                console.log(form);
                this.axios.post("/article/insert_gu_article_comment", this.qs.stringify(form))
                    .then((rs) => {
                        if (rs.data.code == 0) {
                            this.$message({
                                message: '回复成功',
                                type: 'success'
                            });
                            this.dialogVisible = false;
                        } else {
                            this.$message(rs.data.msg);
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        }
    }
</script>
<style>
    .opt a {
        cursor: pointer;
        color: green;
        margin-right: 20px;
        display: inline-block;
    }

    .add {
        width: 100%;
        height: 50px;
        text-align: left;
    }

    .Bdh {
        height: 50px;
        width: 100%;
        line-height: 40px;
    }
</style>